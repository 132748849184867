<template>
  <div class="frame-wrapper">
    <iframe class="evo-frame" src="https://clientarea.evolution.com/evolution/games" />
  </div>
</template>

<script>
export default {
  name: 'EvolutionAssets',
}
</script>

<style scoped>
.frame-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.evo-frame {
  position: absolute;
  top: -80px;
  height: calc(100vh + 80px);
  width: 100%;
}
</style>
