<template>
  <div>{{ errMsg }}</div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import { getConfluencePage } from '@/api/confluencePage'

export default {
  name: 'PublicPage',
  setup() {
    const errMsg = ref('')

    const route = useRoute()
    const { pageId } = route.params

    const decodeHtmlSpecialChar = (html) => {
      const textarea = document.createElement('textarea')
      textarea.innerHTML = html
      return textarea.value
    }

    onMounted(async () => {
      const data = await getConfluencePage(`${pageId}?expand=body.styled_view.webresource.tags.all`)
      const { statusCode } = data

      if (!statusCode) {
        const {
          value: html,
          webresource: {
            tags: { all: link },
          },
        } = data.body.styled_view

        document.write(decodeHtmlSpecialChar(html))

        const style = `
          <style>
            body {
              padding: 40px;
              font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Noto Sans", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif;
            }

            hr {
              margin: 40px 0;
            }

            table {
              width: 100%;
              margin-bottom: 40px!important;
            }

            .confluence-information-macro-body {
              line-height: 2;
            }
          </style>
        `
        document.head.insertAdjacentHTML('beforeend', style)
        document.head.insertAdjacentHTML('beforeend', link)
      }
    })

    return { errMsg }
  },
}
</script>
