import axios from 'axios'

// eslint-disable-next-line import/prefer-default-export
export const getConfluencePage = async (pageId) => {
  try {
    const res = await axios.post(`/api/getConfluencePage/${pageId}`)
    return res.data
  } catch (error) {
    return error.response.data
  }
}
